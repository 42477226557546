import * as React from 'react';

import { Text, BlogImage, BlogLiterature } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import functionalGame from '../../../images/educational-corner/blog19/funkcionalna-igra.png';
import gameWithRules from '../../../images/educational-corner/blog19/igra-s-pravilima.png';
import constructiveGame from '../../../images/educational-corner/blog19/konstruktivna-igra.png';
import symbolicGame from '../../../images/educational-corner/blog19/simbolicka-igra.png';

const CognitiveDevelopmentOfTheGame = () => {
  return (
    <PageNew
      title="KOKOLINGO - Spoznajni razvoj igre"
      description="Kada uočite da dijete nema razvijenu igru na razini 
      koja bi se očekivala za njegovu dob, bitno je razgovarati sa 
      stručnjakom kako biste vidjeli na koji način dodatno 
      podržati dijete u njegovu razvoju."
      headline="Spoznajni razvoj igre"
    >
      <Text as="p">
        S igrom započinjemo gotovo od samog rođenja i ako si dopustimo, igrati
        se zapravo nikada ni ne prestajemo. Čak i u starijoj životnoj dobi
        možemo se igrati na različite načine (primjerice, kartanje kao igra s
        pravilima).
      </Text>
      <Text as="p">
        Ako promatramo djetetov razvoj, paralelno možemo promatrati i djetetov
        razvoj igre.{' '}
        <b>
          Dijete se ne igra na isti način s tri mjeseca, kao što se igra s tri
          godine.
        </b>{' '}
        S tri mjeseca gleda i dodiruje meke predmete (loptice), a s tri godine
        dijete se može pretvarati da je od tih istih loptica napravilo sladoled.
      </Text>
      <Text as="p">
        <b>
          Kada uočite da dijete nema razvijenu igru na razini koja bi se
          očekivala za njegovu dob, bitno je razgovarati sa stručnjakom kako
          biste vidjeli na koji način dodatno podržati dijete u njegovu razvoju.
        </b>{' '}
        S obzirom na djetetov spoznajni razvoj, razvoj se odnosi na mentalne
        procese uz pomoć kojih pokušava razumjeti i prilagoditi svijet koji ga
        okružuje. Postoje četiri spoznajne razine igre: funkcionalna,
        konstruktivna, simbolička i igra s pravilima.
      </Text>
      <Text as="h2">Funkcionalna igra</Text>
      <Text as="p">
        Funkcionalna je igra vrsta igre u kojoj dijete dominantno istražuje
        predmete i koristi i isprobava funkcije tih predmeta. Primjerice, malom
        djetetu možemo dati zvečku koja šušti. Dijete će se njome igrati tako da
        je pomiče, drma, baca, opipava, udara po podu i sl. Dijete se neće
        pretvarati da u rukama ima nešto drugo (primjerice, štap za bubanj),
        nego će se doslovno koristiti tim predmetom i uživati u njemu. Igračke u
        funkcionalnoj igri mogu biti lopte, baloni, tunel, zvučne igračke i sl.{' '}
        <b>
          Ova se igra pojavljuje već s tri mjeseca kada dijete pruža svoje
          ručice prema lopticama koje mu vise iznad glave gdje beba uživa u igri
          da udara loptice i da se loptice pomiču.
        </b>{' '}
        Kako dijete raste, tako funkcionalna igra postaje sve složenija. U
        početku beba samo dodiruje predmete koji joj se nalaze iznad glave,
        kasnije ih baca, stavlja u usta, liže i sl. A još kasnije umeće predmete
        na njihova mjesta, ponavlja zvukove koje čuje i sl.
      </Text>
      <Text as="p">
        Djetetovu igru možemo proučavati tako da vidimo istražuje li primjereno
        predmete ili to čini na vrlo neuobičajen način (primjerice, dijete od 18
        mjeseci i dalje stavlja kamenčiće u usta) ili se koristi predmetima za
        zadovoljavanje senzoričkih potreba (primjerice, pojedina djeca mogu
        uživati u vrlo glasnoj glazbi, buci ili šuštanju zvečke, a nekoj djeci
        glasnija glazba, buka ili šuštanje zvečke može predstavljati frustraciju
        jer su podražajno vrlo osjetljiva).
      </Text>
      <Text as="p">
        Dijete u njegovoj funkcionalnoj igri možemo podržati tako da mu
        osiguramo poticajnu okolinu (primjerice, različitim igračkama kojima
        može manipulirati i aktivirati svoja osjetila – mekane plišane igračke,
        igračke koje ispuštaju zvuk, senzoričke loptice i sl.). Važno je
        naglasiti kako bebe u toj dobi i dalje preko usta vole istraživati
        svijet i bitno je osigurati igračke koje beba ne može progutati.
      </Text>
      <BlogImage
        imgSrc={functionalGame}
        imgAlt="Funkcionalna igra"
        imgTitle="Funkcionalna igra"
      />
      <Text as="h2">Konstruktivna igra</Text>
      <Text as="p">
        Konstruktivna je igra vrsta igre u kojoj dijete barata predmetima kako
        bi napravilo nešto, odnosno kako bi postiglo određeni cilj. Primjerice,
        dijete može slagati kocke kako bi sagradilo dvorac. Ako dijete samo
        slaže kocke uvis, bez cilja da složi visoki toranj, to je onda
        funkcionalna igra jer samo manipulira predmetima bez nekog cilja,
        odnosno bez konstruiranja nečega.{' '}
        <b>Ovaj tip igre pojavljuje se u dobi od oko 12 mjeseci.</b> S vremenom
        konstruktivna igra postaje sve složenija. Primjerice, slaganje
        kompleksnih puzzla ili lijepljenje makete svemirskoga broda.
      </Text>
      <Text as="p">
        U djetetovoj igri možemo proučavati ima li dijete neki cilj u svojoj
        igri (da napravi nešto ili izgradi) jer ako nema, odnosno ako je jedini
        cilj igre manipulacija predmetom, tada je riječ o funkcionalnoj igri.
      </Text>
      <Text as="p">
        Konstruktivnu igru kod djeteta možemo poticati, primjerice,
        verbaliziranjem („Vidim da slažeš toranj“; „Odlučio si posložiti
        vojnike“ i sl.); nabavljanjem igračaka koje potiču „stvaranje“ (kocke,
        umetaljke, tijesto i kalupi za tijesto i sl.) i dr.
      </Text>
      <BlogImage
        imgSrc={constructiveGame}
        imgAlt="Konstruktivna igra"
        imgTitle="Konstruktivna igra"
      />
      <Text as="h2">Simbolička igra ili igra pretvaranja</Text>
      <Text as="p">
        Simbolička je igra vrsta igre u kojoj djeca upotrebljavaju predmet ili
        osobu kao simbol nečeg drugog. Primjerice, koriste se olovkom kao
        letjelicom ili koriste prijatelje kao pljačkaše koje onda on/a treba
        uloviti (jer je on/a policajac).{' '}
        <b>Ova vrsta igre počinje se razvijati negdje oko 18. mjeseca.</b> Preko
        ove vrste igre djeca imaju priliku zadovoljiti svoje potrebe i želje.
        Primjerice, dijete koje je bilo na cijepljenju kod pedijatra dolaskom
        kod kuće ili u dječji vrtić može se početi igrati s lutkom koja glumi
        pacijenta, a dijete je doktor koji njeguje pacijenticu. Nije ništa
        neobično ako ste kod svojeg djeteta primijetili da se igra nove igre
        “potresa“ gdje se djeca pretvaraju da se sve trese. Slično se događalo i
        tijekom Domovinskoga rata kada su se mnoga djeca igrala rata.{' '}
        <b>
          Djeca često mogu “prorađivati” svoju svakodnevicu uz pomoć simboličke
          igre.
        </b>
      </Text>
      <Text as="p">
        U djetetovoj simboličkoj igri može se pratiti ima li dijete sposobnost
        imitacije simboličke upotrebe predmeta. Također, možemo promatrati
        dovodi li dijete likove s kojima se igra u kakve odnose i razvija li
        priču.
      </Text>
      <Text as="p">
        Razvoj simboličke igre možemo poticati, primjerice, igranjem uloga.
        Nabavite djetetu set (za doktora, kuhara, majstora…) i igrajte se kao da
        ste kod liječnika, kao da ste na čajanci ili kao da treba nešto
        popraviti.
      </Text>
      <Text as="p">
        Ako se dijete ne može igrati na simbolički način, preporuka je da
        porazgovarate sa stručnjakom mentalnog zdravlja kako biste dodatno
        vidjeli kako podržati svoje dijete.
      </Text>
      <BlogImage
        imgSrc={symbolicGame}
        imgAlt="Simbolička igra ili igra pretvaranja"
        imgTitle="Simbolička igra"
      />
      <Text as="h2">Igra s pravilima</Text>
      <Text as="p">
        Igra s pravilima vrsta je igre u kojoj se igra prema već unaprijed
        poznatim pravilima i koja najčešće ima element natjecanja. Važno je
        djetetu omogućiti iskustvo frustracije u pogledu nemogućnosti stalnog
        pobjeđivanja u društvenim igrama. Iako roditelji često polaze od dobre
        namjere i djetetu stalno puštaju da pobijedi,{' '}
        <b>važno je razvijati životnu vještinu nošenja s frustracijom</b> jer će
        se uz pomoć nje dijete u budućnosti s time lakše nositi ako smo ga
        izlagali sličnim podražajima.
      </Text>
      <Text as="p">
        U igri s pravilima možemo pratiti može li dijete slijediti pravila, kako
        se ponaša tijekom same igre, kako reagira kada izgubi u igri, kakve ima
        reakcije i sl.
      </Text>
      <Text as="p">
        Pri igranju igara s pravilima djeci treba pomoći ako dođe do burnih
        emotivnih reakcija zbog poraza; važno ih je učiti kako pobjednik ne
        znači da su svi ostali gubitnici (koji manje vrijede) i sl.
      </Text>
      <BlogImage
        imgSrc={gameWithRules}
        imgAlt="Igra s pravilima"
        imgTitle="Igra s pravilima"
      />
      <Text as="p">
        Jedan je od načina kako se možete igrati s djetetom i putem aplikacije
        Kokolingo. Osim što je djeci zanimljiva jer je riječ o digitalnom alatu,
        uz pomoć nje uvježbava se pravilan izgovor glasova.
      </Text>
      <Text as="p">
        Igrajte se sa svojom djecom jer time možete poticati zdravi psihofizički
        razvoj djeteta. Uostalom, ona neće zauvijek biti malena. Iskoristite
        trenutke jer s vremenom roditelji neće biti u fokusu za igru, nego će se
        dijete htjeti igrati samo s vršnjacima.
      </Text>
      <Text as="p">
        Dario Antić, magistar psihologije, specijalizant transakcijske analize,
        praktičar terapije igrom
      </Text>
      <BlogLiterature>
        <ul>
          <li>
            Klarin, M. (2017). <i>Psihologija dječje igre</i>. Zadar:
            Sveučilište u Zadru.
          </li>
          <li>
            Smilansky, S. (1968.){' '}
            <i>
              The effects of sociodramatic play on disadvantaged preschool
              children
            </i>
            . Oxford, England: John and Sons.
          </li>
          <li>
            Starc, B., Čudina – Obradović, M., Pleša, A., Profaca, B. i Letica,
            M. (2004).{' '}
            <i>Osobine i psihološki uvjeti razvoja djeteta predškolske dobi</i>.
            Zagreb: Golden marketing – Tehnička knjiga.
          </li>
        </ul>
      </BlogLiterature>
      <SectionBlog />
    </PageNew>
  );
};

export default CognitiveDevelopmentOfTheGame;
